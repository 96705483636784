import React from 'react'
import {BorderBox, Box, Flex, Heading, Link, StyledOcticon, Text} from '@primer/components'
import {CodeIcon, MortarBoardIcon, PersonIcon, OrganizationIcon} from '@primer/octicons-react'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Footer from '../components/Footer'

const gutter = 5

export default function Index() {
  return (
    <Layout>
      <Hero />
      <Box bg="blue.1" color="black" px={4} pt={12} pb={4}>
        <Box className="container-xl" px={2}>
          <Heading fontSize={6} textAlign="center" lineHeight={1}>
            Highlights
          </Heading>
          <Text as="p" textAlign="center" mb={8}>
            Check out these projects:
          </Text>
          <Flex flexWrap="wrap">
            <Promo title="LoaderWatch" href="https://github.com/andrew-boyarshin/LoaderWatch">
              <Text as="p" m={0}>
                Windows 10 PE image loader (LDR) NTDLL component toolbox.
              </Text>
              <Text as="p" m={2} mb={1}>
                <StyledOcticon icon={MortarBoardIcon} verticalAlign="middle" /> Windows Internals, RE
              </Text>
              <Text as="p" m={2} mt={1} mb={1}>
                <StyledOcticon icon={CodeIcon} verticalAlign="middle" /> C, C#
              </Text>
              <Text as="p" m={2} mt={1}>
                <StyledOcticon icon={PersonIcon} verticalAlign="middle" /> Personal Project
              </Text>
            </Promo>
            <Promo title="CsprojToVs2017" href="https://github.com/hvanbakel/CsprojToVs2017">
              <Text as="p" m={0}>
                Tooling for converting pre-2017 project to the new Visual Studio 2017 format.
              </Text>
              <Text as="p" m={2} mb={1}>
                <StyledOcticon icon={MortarBoardIcon} verticalAlign="middle" /> MSBuild Tooling, Managed DevOps
              </Text>
              <Text as="p" m={2} mt={1} mb={1}>
                <StyledOcticon icon={CodeIcon} verticalAlign="middle" /> C#
              </Text>
              <Text as="p" m={2} mt={1}>
                <StyledOcticon icon={OrganizationIcon} verticalAlign="middle" /> Maintainer, Contributor
              </Text>
            </Promo>
            <Promo title="ReactOS" href="https://github.com/andrew-boyarshin/reactos">
              <Text as="p" m={0}>
                A free Windows-compatible Operating System.
              </Text>
              <Text as="p" m={0}>
                Fork to target modern Windows features and applications.
              </Text>
              <Text as="p" m={2} mb={1}>
                <StyledOcticon icon={MortarBoardIcon} verticalAlign="middle" /> Windows Internals, Kernel Development, RE
              </Text>
              <Text as="p" m={2} mt={1} mb={1}>
                <StyledOcticon icon={CodeIcon} verticalAlign="middle" /> C
              </Text>
              <Text as="p" m={2} mt={1}>
                <StyledOcticon icon={PersonIcon} verticalAlign="middle" /> Personal Fork
              </Text>
            </Promo>
          </Flex>
        </Box>
      </Box>
      <Footer />
    </Layout>
  )
}

const A = props => <Link {...props} />

A.defaultProps = {
  color: 'inherit',
  underline: false
}

const Promo = ({title, href, children, ...rest}) => (
  <Box width={[1, 1, 1 / 3]}>
    <BorderBox
      bg="white"
      position="relative"
      {...rest}
      boxShadow="medium"
      rounded={1}
      mr={[0, 0, gutter]}
      mb={[6, 6, 8]}
    >
      <Box bg="blue.6" position="absolute" top={0} left={0} py={1} width="100%" />
      <Box p={3}>
        <Heading fontSize={4}>
          <A href={href}>{title}</A>
        </Heading>
        {children}
      </Box>
    </BorderBox>
  </Box>
)
